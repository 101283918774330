.wrapper {
    display: flex;
    justify-content: center;
    align-items: start;
}

.avatar {
    width: 500px;
    height: 500px;
    background-color: #bfbfbf;
}

.wrapper-content {
    width: 500px;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divider {
    width: 1px;
    height: 200px;
    margin: 0 64px;
    border-radius: 8px;
    background-color: #04abd3;
}

.wrapper-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.profile-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.profile-item-label {
    font-weight: normal;
    font-size: 14px;
    color: #434343;
}

.profile-item-data {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}
