.ant-table-tbody > tr > td {
  padding: 4px 16px !important;
}
.border-none .ant-table-tbody > tr > td,
.border-none .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.ant-table-thead > tr > th.small {
  padding-top: 4px;
  padding-bottom: 4px;
}
.ant-table-thead > tr > th.current-region {
  background-color: #004688 !important;
  color: white;
}
.ant-table-thead > tr > th.font-bold {
  font-weight: bold;
}
.ant-table-tbody > tr > td.budget-cell {
  background-color: #04abd3;
  color: white;
  border-bottom: 2px solid #004688;
  border-top: 2px solid #004688;
}
.ant-table-tbody > tr > td.budget-cell.ant-table-cell-row-hover {
  background: #04abd3 !important;
  color: white;
}
.ant-table-tbody > tr > td.grouped-cells {
  border-bottom: 2px solid #004688;
}
.ant-table-thead > tr > th.child-header {
  border-bottom: 2px solid #004688;
  border-top: 2px solid #004688;
}
.ant-table-tbody > tr > td.last-cell {
  border-bottom: 2px solid #004688;
}

.borderless-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

/* REMOVE BACKGROUND COLOR ON TABLE HEADERS */
.ant-table-thead > tr > th {
  background-color: #fff !important;
  text-transform: uppercase;
}

/* RESET POPCONFIRM */
#adjustment-popconfirm .ant-popover-message-title {
  padding-left: 0;
}
#adjustment-popconfirm button.ant-btn-sm {
  height: 30px;
  min-width: 80px;
}
#adjustment-popconfirm textarea:hover,
#adjustment-popconfirm textarea:focus {
  border-width: 2px !important;
  box-shadow: none !important;
}

/* CAPACITY TABLE */
.col-span {
  column-span: 2;
}
.th-center {
  text-align: center !important;
}
.border-r-black {
  border-right-color: #00000047 !important;
}
