:root {
  --primary-color: #1890ff;
  --background-color: #f0f2f5;
  --card-color: #ffffff;
  --text-color: rgba(0,0,0,.85);
  --background-color-gray: #deefff;
  --bg-primary: #f0f2f5;
}

.dark-theme {
  --primary-color: #1890ff;
  --background-color: #141414;
  --card-color: #2d2d2d;
  --text-color: #fff;
  --background-color-gray: #2d2d2d;
  --bg-primary: #2d2d2d;
}

.ant-layout {
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
}

.card {
  color: var(--text-color) !important;
  background-color: var(--card-color) !important;
}

.card-selected {
  color: var(--primary-color) !important;
  background-color: var(--background-color) !important;
}

.title {
  color: var(--text-color) !important;
}

.shadow-xs {
  box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

.hidden {
  display: none;
}

.flex {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: auto 1;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}
.block {
  display: block;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.items-center {
  align-items: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.ant-page-header-heading-extra {
  display: flex;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.self-end {
  align-self: flex-end;
}

/* PADDING */
.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}
.p-1\/3 {
  padding: 0.3rem;
}
.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-1\/3 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.p-2 {
  padding: 0.75rem;
}

.p-4 {
  padding: 4rem;
}

.py-2 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-3 {
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

.px-2 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-3 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-3 {
  padding: 16px !important;
}

.p-4 {
  padding: 24px;
}

.py-4 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.p-5 {
  padding: 2rem;
}

.pb-3 {
  padding-bottom: 16px;
}

.pb-16 {
  padding-bottom: 4.5rem;
}

/* MARGINS */
.m-0 {
  margin: 0;
}

.my-2 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-3 {
  margin-top: 0.85rem;
  margin-bottom: 0.85rem;
}
.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-2 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-1\/3 {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 2rem;
}

.mr-2 {
  margin-right: 0.75rem;
}
.mr-3 {
  margin-right: 16px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.75rem;
}
.mb-3 {
  margin-bottom: 16px;
}
.mb-4 {
  margin-bottom: 24px;
}

.ml-2 {
  margin-left: 0.75rem;
}

.ml-3 {
  margin-left: 0.85rem;
}

.ml-4 {
  margin-left: 0.9rem;
}

.ml-5 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-0 {
  margin-left: 0 !important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-2 {
  gap: 2rem !important;
}

.gap-3 {
  gap: 3rem !important;
}

/* DIMENSIONS */
.w-1 {
  width: 1rem !important;
}
.w-2 {
  width: 2rem !important;
}
.w-3 {
  width: 3rem !important;
}
.w-4 {
  width: 4rem !important;
}

.h-1 {
  height: 1rem !important;
}
.h-2 {
  height: 2rem !important;
}
.h-3 {
  height: 3rem !important;
}
.h-4 {
  height: 4rem !important;
}


.w-14 {
  width: 14rem !important;
}

.w-20 {
  width: 20rem;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}
.w-1\/4 {
  width: 40%;
}

/* COLORS */
.bg-white {
  background: #fff;
}

.bg-secondary {
  background-color: #efefef;
}

.bg-branding-blue {
  background-color: #004688;
}

.bg-branding-teal {
  background-color: #04abd3;
}

.bg-blue-transparent {
  background-color: var(--background-color-gray);
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-primary {
  background-color: var(--bg-primary) !important;
}
.bg-gray-200 {
  background-color: #eff2f5;
}

.ant-layout-sider,
.ant-page-header-ghost {
  background-color: #fff !important;
}

.text-white {
  color: #fff;
}
.text-secondary {
  color: #bfbfbf;
}
.text-black {
  color: #000;
}
.text-black-50 {
  color: rgb(0, 0, 0, 0.5);
}
.text-gray {
  color: rgb(128, 128, 128) !important;
}
.text-gray-300 {
  color: #e6e8ec;
}

.text-branding-blue {
  color: #004688;
}

.text-branding-teal {
  color: #04abd3;
}
.text-blue {
  color: #1890ff;
}

.bg-gray {
  background: #fafafa;
}
/* FONTS */
.font-bold {
  font-weight: bold;
}
.font-medium {
  font-weight: 500;
}
.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 16px; /* 16px */
}
.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.text-lg {
  font-size: 16px;
  line-height: 16px;
}
.text-xl {
  font-size: 20px;
  line-height: 16px;
}
.letter-28 {
  letter-spacing: 0.28px;
}

/* OVERFLOW */
.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

/* BORDERS */
.border-none {
  border: none;
}

.border-t-none {
  border-top: none;
}

.border-2-blue {
  border: 2px solid #40a9ff;
}

.border {
  border: 1px solid #d9d9d9;
}

.border-2 {
  border: 2px solid #d9d9d9 !important;
}
.border-w-2 {
  border-width: 2px !important;
}

.border-b-2 {
  border-bottom: 2px solid #d9d9d9;
}

.border-b-none {
  border-bottom: none !important;
}

.border-t-2 {
  border-top: 2px solid #d9d9d9;
}
.border-t {
  border-top: 1px solid #d9d9d9;
}

.border-r {
  border-right: 1px solid #d9d9d9;
}

.border-r-2 {
  border-right: 2px solid #d9d9d9;
}

.border-r-4 {
  border-right: 4px solid #d9d9d9;
}

.border-l-2 {
  border-left: 2px solid #d9d9d9;
}
.border-l-4 {
  border-left: 4px solid #d9d9d9;
}
.border-l {
  border-left: 1px solid #d9d9d9;
}

.border-x-2 {
  border-left: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
}

.border-y-2 {
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
}

.border-b {
  border-bottom: 1px solid rgba(209, 213, 219, 1);
}

.border-b-4 {
  border-bottom: 4px solid rgba(209, 213, 219, 1);
}

.border-branding-blue {
  border-color: #004688;
}
.border-blue {
  border-color: #1890ff;
}
.border-gray-300 {
  border-color: #d9d9d9 !important;
}
.border-gray-100 {
  border-color: rgb(217, 217, 217, 0.3);
}

/* BORDER RADIUS */
.rounded-sm,
.rounded-sm .ant-select-selector,
.select-options.rounded-sm > .ant-select-selector {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.rounded-md {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.rounded-full {
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}
.rounded-md-ry {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rounded-md-ly {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* HOVER */
.hover\:text-blue:hover {
  color: #54b1ff;
}
.hover\:bg-primary:hover {
  background-color: #f0f2f5 !important;
}

.selected-text-blue {
  color: #0469c7;
}

/* CURSOR */
.pointer {
  cursor: pointer;
}

button:disabled {
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

*[role='button'],
*[role='tab'] {
  cursor: pointer;
}

/* FORCE UPDATE TO THE NAVIGATOR */
.ant-tree-switcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-picker-range {
  height: 100%;
}

.ant-picker.rounded-md {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.navigator-panel {
  /*height: calc(100vh - 92px);*/
  height: 100vh !important;
  overflow-y: auto;
}

.h-92 {
  height: calc(100vh - 62px);
}
.min-h-screen {
  min-height: 100vh;
}
.btn-height {
  height: 42px !important;
}
.h-42 {
  height: 42px !important;
}
.h-40 {
  height: 40px !important;
}

/* POSITIONS */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.left-0 {
  left: 0;
}

/* RESET MARGINS ON HEADING EXTRA */
.ant-page-header-heading-extra {
  margin: 0 !important;
}

/* RESET SELECT TAG */
.select-options {
  min-width: 150px;
}

.select-options > .ant-select-selector {
  height: 42px !important;
  align-items: center;
  border-width: 2px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.select-35 > .ant-select-selector {
  height: 35px !important;
  display: flex !important;
  align-items: center;
  border: 2px solid #d9d9d9 !important;
}

.prediction-select {
  min-width: 120px;
  max-width: 240px;
  color: #1890ff !important;
}

.tree, ul {
  list-style-type: none;
  color: #888;
}

.tree, ul.nested {
  position: relative;
}

.tree, ul.nested::before {
  position: absolute;
  content: '';
  left: 7.2%;
  width: 4rem;
  height: 100%;
  border-left: 1.3px solid #888;
}

.tree, ul.li.nested::after {
  position: absolute;
  content: '';
  left: 7.2%;
  width: 1.5rem;
  height: 100%;
  border-bottom: 1.3px solid #888;
}

.folder {
  text-transform: capitalize;
}

.folder::before {
  display: inline-block;
  content: '📁';
}

.tree-line {
  margin-left: 1rem;
}
.tree-region {
  margin-left: 1.4rem;
}
.tree-group {
  margin-left: 1.8rem;
}
.tree-category {
  margin-left: 2.2rem;
}
.tree-item {
  margin-left: 2.6rem;
}

.w-50 {
  width: 50px !important;
}

.w-80 {
  width: 80px !important;
}

/* RESPONSIVE */
@media only screen and (min-width: 1200px) {
  .xxl\:w-23 {
    width: 23rem;
  }
}

@media only screen and (min-width: 1440px) {
  .xlg-block {
    display: block;
  }
  .xlg-hidden {
    display: none;
  }
  .menu-wrapper {
    height: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .xl\:w-20 {
    width: 20rem;
  }
  .xl\:block {
    display: block;
  }
  .prediction-select {
    max-width: 500px;
  }
  .navigator-panel {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
}

@media only screen and (min-width: 992px) {
  .lg\:w-16 {
    width: 16rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:block {
    display: block;
  }

  .lg\:no-wrap {
    flex-wrap: nowrap;
  }
  .lg\:w-1\/7 {
    width: 70%;
  }
  .prediction-select {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .md\:w-14 {
    width: 14rem;
  }

  .md\:hidden {
    display: none;
  }

  .md-hidden {
    display: none;
  }

  .md\:block {
    display: none;
  }

  .md\:pr-4 {
    padding-right: 24px !important;
  }
  .md\:p-4 {
    padding: 24px;
  }

  .navigator-panel,
  .h-92 {
    height: calc(100vh - 62px);
  }
  .menu-wrapper {
    height: calc(100vh - 100px);
  }
}

@media only screen and (min-width: 576px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:block {
    display: none;
  }
}

.custom-overlay {
  left: calc(100vw - 330px) !important;
}

/* ANIMATIONS */
.animate-all-500 {
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
.all-500 {
  transition: all 500ms;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -ms-transition: all 500ms;
  -o-transition: all 500ms;
}
.all-15-eio {
  transform: all 0.15s ease-in-out 0s;
  -webkit-transform: all 0.15s ease-in-out 0s;
  -moz-transform: all 0.15s ease-in-out 0s;
  -ms-transform: all 0.15s ease-in-out 0s;
  -o-transform: all 0.15s ease-in-out 0s;
}
div[role='menuitem'].ant-menu-submenu-title {
  padding-left: 14px !important;
}

/* COLLAPSE MENU */
.ant-menu-inline-collapsed .ant-menu-item {
  display: flex;
  align-items: center;
}
.ant-menu-inline-collapsed .ant-menu-submenu .-title-content {
  display: none;
}

/* SPINNER */
.custom-spinner {
  display: flex !important;
  align-items: center;
}
.custom-spinner .ant-spin-text {
  margin-left: 0.75rem;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .ant-page-header-heading-extra {
    display: none;
  }
}

.display-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 16px;
}

.display-grid-horizon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

@media (max-width: 768px) {
  .display-grid-horizon {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 576px) {
  .display-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .display-grid-horizon {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
  }
}

.display-grid-item {
  min-width: 220px;
  min-height: 220px;
  cursor: pointer;
}

.display-grid-item-horizon {
  min-width: 80px;
  min-height: auto;
  cursor: pointer;
}

.min-width-button  {
  min-width: 150px;
}

.min-width-button-add-tree  {
  width: 120px;
  height: 120px;
  cursor: pointer;
}
