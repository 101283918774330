.login-wrapper {
  width: 100%;
}

.ant-input-affix-wrapper-lg {
  padding: 11px 11px !important;
}
.ant-input-affix-wrapper {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.ant-btn-lg {
  line-height: 0px !important;
  padding: 23px 15px !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

@media only screen and (min-width: 768px) {
  .login-wrapper {
    width: fit-content;
    min-width: 450px;
  }
}
