.modal-wrapper .ant-modal-content,
.modal-wrapper .ant-modal-content .ant-modal-header {
  border-radius: 20px 20px 20px 20px;
  width: var(--modal-width);
  height: var(--modal-height);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
