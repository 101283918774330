.ant-upload.ant-upload-select-picture-card {
  border-radius: 99px !important;
  -webkit-border-radius: 99px !important;
  -moz-border-radius: 99px !important;
  -ms-border-radius: 99px !important;
  -o-border-radius: 99px !important;
}
.ant-upload-picture-card-wrapper {
  display: flex !important;
  justify-content: center;
}

.change-profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -11px;
  top: -6px;

  height: 24px;
  width: 24px;
  background-color: #fff;

  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.change-profile-button span.ant-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* RESET INPUTS HOVER */
.ant-input:hover {
  border-width: currentColor !important;
}

.autocomplete-input .ant-select-selector,
.autocomplete-input input {
  height: 100% !important;
}

.autocomplete-input .ant-select-selector {
  align-items: center;
}
